import { NgModule } from '@angular/core';
import { BarcodeScannerComponent } from './barcode-scanner.component';



@NgModule({
  declarations: [
    BarcodeScannerComponent
  ],
  imports: [
  ],
  exports: [
    BarcodeScannerComponent
  ]
})
export class BarcodeScannerModule { }
