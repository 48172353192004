// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  sentryDNS:
    'https://b91404f15f3d4b08bb25be486dfd111e@o1301142.ingest.sentry.io/6536748',
  token: 'e45r9823ejjdthng052e23rmuh',
  url: 'https://srv.avto100.in.ua/api/',
  domen: `srv.avto100.in.ua`,
  url_jwt: 'https://srv.avto100.in.ua/api_jwt/',
  urlGetSite: 'https://srv.avto100.in.ua/api_get/',
  url_jwt_file: 'https://srv.avto100.in.ua/api_jwt_file/',
  api_jwt_file_provider: 'https://srv.avto100.in.ua/api_jwt_file_provider/',
  api_jwt_file_fid_delete: 'https://srv.avto100.in.ua/api_jwt_file_fid_delete/',
  api_jwt_file_fid_create: 'https://srv.avto100.in.ua/api_jwt_file_fid_create/',
  api_jwt_file_fid_disapproved:
    'https://srv.avto100.in.ua/api_jwt_file_fid_disapproved/',
  api_jwt_file_img_brand: 'https://srv.avto100.in.ua/api_jwt_file_img_brand/',
  api_jwt_file_img_prod: 'https://srv.avto100.in.ua/api_jwt_file_img_prod/',
  api_jwt_file_request_ads:
    'https://srv.avto100.in.ua/api_jwt_file_request_ads/',
  api_jwt_file_price: 'https://srv.avto100.in.ua/api_jwt_file_price/',
  api_jwt_file_product_card:
    'https://srv.avto100.in.ua/api_jwt_file_product_card/',
  api_jwt_file_fid_label2: 'https://srv.avto100.in.ua/api_jwt_file_fid_label2',
};
/*

import * as Sentry from '@sentry/angular';
const DOMAIN = 'PLACEHOLDER_DOMAIN'; // Используем плейсхолдер

export const environment = {
  production: true,
  sentryDNS:
    'https://b91404f15f3d4b08bb25be486dfd111e@o1301142.ingest.sentry.io/6536748',
  token: 'e45r9823ejjdthng052e23rmuh',
  url: `https://${DOMAIN}/api/`,
  domen: `${DOMAIN}`,
  url_jwt: `https://${DOMAIN}/api_jwt/`,
  urlGetSite: `https://${DOMAIN}/api_get/`,
  api_jwt_file_fid_delete: `https://${DOMAIN}/api_jwt_file_fid_delete/`,
  api_jwt_file_fid_create: `https://${DOMAIN}/api_jwt_file_fid_create/`,
  api_jwt_file_fid_disapproved: `https://${DOMAIN}/api_jwt_file_fid_disapproved/`,
  url_jwt_file: `https://${DOMAIN}/api_jwt_file/`,
  api_jwt_file_provider: `https://${DOMAIN}/api_jwt_file_provider/`,
  api_jwt_file_img_brand: `https://${DOMAIN}/api_jwt_file_img_brand/`,
  api_jwt_file_img_prod: `https://${DOMAIN}/api_jwt_file_img_prod/`,
  api_jwt_file_request_ads: `https://${DOMAIN}/api_jwt_file_request_ads/`,
  api_jwt_file_price: `https://${DOMAIN}/api_jwt_file_price/`,
  api_jwt_file_product_card: `https://${DOMAIN}/api_jwt_file_product_card/`,
  api_jwt_file_fid_label2: `https://${DOMAIN}/api_jwt_file_fid_label2`,
};

*/

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
