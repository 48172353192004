// Серверный сервис - для обще используемых функций
import { Inject, Injectable, InjectionToken } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DatePipe, DecimalPipe } from '@angular/common';
import * as moment from 'moment';
import { BehaviorSubject, defer, merge, Observable, of, Subject } from 'rxjs';
import { filter } from 'rxjs/operators';
import { InfoList, PermissionUser } from '@shared/main-layout/core/info-list';
import { AbstractControl } from '@angular/forms';
import DurationConstructor = moment.unitOfTime.DurationConstructor;
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { isEqual } from 'lodash';
import { INotificationMessage } from '../interface';
import { environment } from '@env/environment.prod';
export const WINDOW = new InjectionToken<Window>('system.window', {
  factory: () => window,
});

interface Loading {
  status: boolean;
  titleSpinner?: string;
  subtitle?: string;
}

@UntilDestroy()
@Injectable({ providedIn: 'root' })
export class SrvService {
  subjectNotification: BehaviorSubject<INotificationMessage> = new BehaviorSubject<INotificationMessage>(
    null
  );

  toast$: Observable<INotificationMessage> = this.subjectNotification
    .asObservable()
    .pipe(filter((toast: INotificationMessage) => toast !== null));

  evtSource;

  public get loader(): boolean {
    return this._loader;
  }

  public set loader(loader: boolean) {
    if (isEqual(this.loader, loader)) {
      return;
    }
    this._loader = loader;
    this._loader$.next(loader);
  }

  public set loader$(loader: Loading) {
    if (isEqual(this.showLoadInfo$.getValue(), loader)) {
      return;
    }
    this.showLoadInfo$.next(loader);
  }

  showLoadInfo$ = new BehaviorSubject<Loading>({
    status: false,
    titleSpinner: '',
    subtitle: '',
  });

  barcodeData$ = new BehaviorSubject<string>('');
  barcodeProductData$ = new BehaviorSubject<string>('');

  infoList$: BehaviorSubject<InfoList> = new BehaviorSubject<InfoList>({
    catalogBrand: undefined,
    countPeers: 0,
    countPeersBusy: 0,
    countPres: 0,
    requestCount: 0,
    countTask: 0,
    modelShield: '',
    missedPresList: 0,
    missedTaskList: 0,
    order: {
      order: 0,
      orderVin: 0,
      orderNotFound: 0,
      orderSelectProduct: 0,
      orderFastCard: 0,
      orderCallBack: 0,
      missedCall: 0,
      all: 0,
      regClient: 0,
    },
    packing: {
      delivery: 0,
      self: 0,
      curer: 0,
      all: 0,
    },
    presList: '',
    requestList: '',
    peers: [],
    taskList: '',
    taskListOtherArchive: '',
    taskListArchive: '',
    taskListOther: '',
  });
  information$: Observable<InfoList> = this.infoList$.asObservable();

  // роли и уровень доступа сотрудника
  permission$: BehaviorSubject<PermissionUser> = new BehaviorSubject<PermissionUser>(
    null
  );
  userPerm: Observable<PermissionUser> = this.permission$
    .asObservable()
    .pipe(filter((val: PermissionUser) => val != null));

  // старт стоп ССЕ
  startSSE$: Subject<any> = new Subject<any>();
  startSSEToken: Observable<any> = this.startSSE$.asObservable();

  // tslint:disable-next-line:variable-name
  private _loader = false;
  // tslint:disable-next-line:variable-name
  private _loader$ = new Subject<boolean>();

  constructor(
    private snackBar: MatSnackBar,
    private datePipe: DatePipe,
    private mathPipe: DecimalPipe,
    @Inject(WINDOW) private window: Window
  ) {
    this.startSSEToken.subscribe(() => this.reconectSSE());
    this._loader$
      .pipe(untilDestroyed(this))
      .subscribe((value) => (this.loader = value));
  }

  reconectSSE(): void {
    if (this.evtSource) {
      this.evtSource.close();
    }

    this.evtSource = new EventSource(
      'https://' +
        environment.domen +
        '/sse?token=' +
        localStorage.getItem('auth-token'),
      {
        withCredentials: true,
      }
    );
  }

  // старт процедуры server-sent events
  startSSE(): void {
    this.evtSource.addEventListener('phoneCall', (event) => {
      const data = JSON.parse(event.data);
      this.window.open(
        '/crm/user-card-phone/' + data.userHash + '/' + data.idCall,
        '_blank'
      );
    });

    this.evtSource.addEventListener('notification', (event) => {
      this.subjectNotification.next(JSON.parse(event.data));
    });

    this.evtSource.addEventListener('infoList', (event) => {
      this.infoList$.next(JSON.parse(event.data));
    });
  }

  stopSSE(): void {
    this.evtSource.close();
  }

  // Снек бар
  public successMessage(message: string): void {
    this.snackBar.open(message, 'Закрыть', {
      panelClass: 'snack-bar-success',
      duration: 4000,
      horizontalPosition: 'right',
      verticalPosition: 'top',
    });
  }

  public errorMessage(message: string): void {
    this.snackBar.open(message, 'Закрыть', {
      panelClass: 'snack-bar-danger',
      duration: 4000,
      horizontalPosition: 'right',
      verticalPosition: 'top',
    });
  }

  public warningMessage(message: string): void {
    this.snackBar.open(message, 'Закрыть', {
      panelClass: 'snack-bar-warning',
      duration: 4000,
      horizontalPosition: 'right',
      verticalPosition: 'top',
    });
  }

  public primaryMessage(message: string, duration = 4000): void {
    this.snackBar.open(message, 'Закрыть', {
      panelClass: 'snack-bar-primary',
      duration,
      horizontalPosition: 'right',
      verticalPosition: 'top',
    });
  }

  public convertDate(date): string {
    let dateInfo = '';
    if (date) {
      dateInfo = this.datePipe.transform(date, 'yyyy-MM-dd');
    }
    return dateInfo;
  }

  public convertDateText(date): string {
    return this.datePipe.transform(date, 'dd-MM-yyyy');
  }

  public convertDateTime(date): string {
    return this.datePipe.transform(date, 'yyyy-MM-dd HH:mm');
  }

  public convertDateTimeInput(date): string {
    return this.datePipe.transform(date, 'yyyy-MM-ddTHH:mm');
  }

  public convertDateInput(date): string {
    return this.datePipe.transform(date, 'yyyy-MM-dd');
  }

  // Конвертация даты в тип dd.MM.yyyy
  /*
      public convertDatePoint(date) {
        return this.datePipe.transform(date, 'dd.MM.yyyy');
      }
    */

  public roundNumber(num: number): any {
    return this.transformRemoveComa(this.mathPipe.transform(num, '1.1-2'));
  }

  getDatesRange(startDate: string, endDate: string, type: number): any {
    const dateArray = [];
    let format = '';
    let addFormat: DurationConstructor = 'days';
    switch (type) {
      case 1:
        format = 'YYYY-MM-DD HH';
        addFormat = 'hours';
        endDate = moment(endDate).add(1, 'days').toString();
        break;
      case 2:
        format = 'YYYY-MM-DD';
        addFormat = 'days';
        break;

      case 3:
        format = 'YYYY-MM';
        addFormat = 'months';
        break;
      case 4:
        format = 'YYYY';
        addFormat = 'years';
        break;
    }

    let currentDate = moment(startDate);
    const stopDate = moment(endDate);
    while (currentDate <= stopDate) {
      dateArray.push(moment(currentDate).format(format));
      currentDate = moment(currentDate).add(1, addFormat);
    }
    return dateArray;
  }

  public firstDayOnMonth(): any {
    const date = new Date();
    const y = date.getFullYear();
    const m = date.getMonth();
    return new Date(y, m, 1);
  }

  public lastDayOnMonth(): any {
    const date = new Date();
    const y = date.getFullYear();
    const m = date.getMonth();
    return new Date(y, m + 1, 0);
  }

  public firstDayCurrYear(): any {
    const today = new Date();
    const currentYear = today.getFullYear();
    return new Date(currentYear, 0, 1);
  }

  public lastDayCurrYear(): any {
    const today = new Date();
    const currentYear = today.getFullYear();
    return new Date(currentYear, 11, 31);
  }

  // Перевод кол. секунд в формат hh:mm
  public timeFormat(sec: number): string {
    function num(val): string {
      val = Math.floor(val);
      return val < 10 ? '0' + val : val;
    }

    const hours = (sec / 3600) % 3600;
    const minutes = (sec / 60) % 60;

    return num(hours) + ':' + num(minutes);
  }

  // вычисление процента
  getPercent(fact, plan): number {
    return +this.roundNumber((fact * 100) / plan);
  }

  // скрыть номер
  public hidePhone(phone): string {
    return (
      phone.substring(0, 3) +
      '' +
      phone.substring(3, phone.length - 4).replace(/[0-9]/g, 'x') +
      '' +
      phone.substring(phone.length - 4)
    );
  }

  public isNumber(n): boolean {
    return /^-?[\d.]+(?:e-?\d+)?$/.test(n);
  }

  public dynamicColors(label?): string {
    const r = Math.floor(Math.random() * 255);
    const g = Math.floor(Math.random() * 255);
    const b = Math.floor(Math.random() * 255);

    if (label.indexOf('_fed') + 1) {
      return 'rgb(' + 0 + ',' + 39 + ',' + 255 + ')';
    } else {
      return 'rgb(' + r + ',' + g + ',' + b + ')';
    }
  }

  // Получить диапазон декад по дате
  /*
  getDecadeRange(date: Date): Date[] {
    // Дата начало диапазона
    let dtBeg: Date;
    // Дата конца диапазона
    let dtEnd: Date;

    // Первая декада
    const dt_Beg_first_decade = new Date(
      date.getFullYear(),
      date.getMonth(),
      1
    );
    const dt_End_first_decade = new Date(
      date.getFullYear(),
      date.getMonth(),
      10
    );

    if (date >= dt_Beg_first_decade && date <= dt_End_first_decade) {
      dtBeg = dt_Beg_first_decade;
      dtEnd = dt_End_first_decade;
    }

    // Вторая декада
    const dt_Beg_second_decade = new Date(
      date.getFullYear(),
      date.getMonth(),
      11
    );
    const dt_End_second_decade = new Date(
      date.getFullYear(),
      date.getMonth(),
      20
    );

    if (date >= dt_Beg_second_decade && date <= dt_End_second_decade) {
      dtBeg = dt_Beg_second_decade;
      dtEnd = dt_End_second_decade;
    }

    // Третья декада
    const dt_Beg_third_decade = new Date(
      date.getFullYear(),
      date.getMonth(),
      21
    );
    const dt_End_third_decade = new Date(
      date.getFullYear(),
      date.getMonth() + 1,
      0
    );

    if (date >= dt_Beg_third_decade && date <= dt_End_third_decade) {
      dtBeg = dt_Beg_third_decade;
      dtEnd = dt_End_third_decade;
    }

    return [dtBeg, dtEnd];
  }
*/

  transformRemoveComa(value: string): string {
    if (value !== undefined && value !== null) {
      return value.replace(/,/g, '');
    } else {
      return '';
    }
  }

  valueChanges<T>(control: AbstractControl): Observable<T> {
    const initialValue: Observable<T> = defer(() => of(control.value));
    const valueChanges: Observable<T> = control.valueChanges;

    return merge(initialValue, valueChanges);
  }

  public replaceGlobally(
    original: string,
    searchTxt: string,
    replaceTxt: string
  ): any {
    const regex = new RegExp(searchTxt, 'g');
    return original.replace(regex, replaceTxt);
  }
}
