import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { OrderCountList } from '@shared/main-layout/core/info-list';
import { IOrderList } from '../../interface';

@Component({
  selector: 'lib-order-indicator',
  templateUrl: './order-indicator.component.html',
  styleUrls: ['order-indicator.component.scss'],
})
export class OrderIndicatorComponent implements OnChanges {
  @Input() orderList: OrderCountList;

  constructor() {}
  orderListInfo: IOrderList[];

  ngOnChanges(changes: SimpleChanges): void {
    this.orderListInfo = [
      {
        link: '/crm/call-list',
        title: 'Не дозвонились',
        number: this.orderList?.missedCall ?? 0,
      },
      {
        link: '/crm/order-list',
        title: 'Перезвонить',
        number: this.orderList?.orderCallBack ?? 0,
        queryParams: {
          status: '5-6',
        },
      },
      {
        link: '/crm/order-list',
        title: 'Быстрый заказ',
        number: this.orderList?.orderFastCard ?? 0,
        queryParams: {
          status: '4-8-22',
        },
      },
      {
        link: '/crm/order-list',
        title: 'Заказ',
        number: this.orderList?.order ?? 0,
        queryParams: {
          status: '1-7-16-20-22',
        },
      },
      {
        link: '/crm/order-list',
        title: 'На применяемость',
        number: this.orderList?.orderSelectProduct ?? 0,
        queryParams: {
          status: '9',
        },
      },
      {
        link: '/crm/order-list',
        title: 'Vin запрос',
        number: this.orderList?.orderVin ?? 0,
        queryParams: {
          status: '3',
        },
      },
      {
        link: '/crm/order-list',
        title: 'Товар не найден',
        number: this.orderList?.orderNotFound ?? 0,
        queryParams: {
          status: '2',
        },
      },
      {
        link: '/crm/order-list',
        title: 'Регистрация клиента',
        number: this.orderList?.regClient ?? 0,
        queryParams: {
          status: '11-12',
        },
      },
      {
        link: '/crm/order-list',
        title: 'Заказы с кабинета',
        number: this.orderList?.cabinetClient ?? 0,
        queryParams: {
          status: '18-19',
        },
      },
      {
        link: '/crm/order-list',
        title: 'Повторные клиенты спец',
        number: this.orderList?.specOrder ?? 0,
        queryParams: {
          status: '99',
        },
      },
    ];
  }
}
