import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { OtherSpr, RequestBody } from '@core/interfaces/interfaces';
import { ApiService } from '@core/services/api.service';
import { SprList } from 'fids-tools';

@Injectable({
  providedIn: 'root',
})
export class PecService {
  constructor(private api: ApiService) {}

  showLoadInfo$ = new BehaviorSubject({
    status: false,
    titleSpinner: '',
    subtitle: '',
  });

  loadProductInfo(value): Observable<any> {
    const q: RequestBody = {
      modelName: 'ProductLibrary',
      calledMethod: 'loadCardInfo',
      methodProperties: {
        type: 1,
        ...value,
      },
    };
    return this.api.post_jwt(q);
  }
  loadBarCodeProd(value): Observable<any> {
    const q: RequestBody = {
      modelName: 'ProductLibrary',
      calledMethod: 'loadBarCodeProd',
      methodProperties: {
        type: 1,
        ...value,
      },
    };
    return this.api.post_jwt(q);
  }

  addBarCodeProd(value): Observable<any> {
    const q: RequestBody = {
      modelName: 'ProductLibrary',
      calledMethod: 'addBarCodeProd',
      methodProperties: {
        type: 1,
        ...value,
      },
    };
    return this.api.post_jwt(q);
  }

  deleteBarCodeProd(value): Observable<any> {
    const q: RequestBody = {
      modelName: 'ProductLibrary',
      calledMethod: 'deleteBarCodeProd',
      methodProperties: {
        type: 1,
        ...value,
      },
    };
    return this.api.post_jwt(q);
  }

  loadProdInfoNomen(value): Observable<any> {
    const q: RequestBody = {
      modelName: 'ProductLibrary',
      calledMethod: 'loadProdInfoNomen',
      methodProperties: {
        type: 1,
        ...value,
      },
    };
    return this.api.post_jwt(q);
  }

  saveNomenInfo(value: any, productId): Observable<any> {
    const q: RequestBody = {
      modelName: 'ProductLibrary',
      calledMethod: 'saveNomenInfo',
      methodProperties: {
        type: 1,
        ...value,
        productId,
      },
    };
    return this.api.post_jwt(q);
  }

  deleteSpecInfo(idRow: number, productId: number): Observable<any> {
    const q: RequestBody = {
      modelName: 'ProductLibrary',
      calledMethod: 'deleteSpecInfo',
      methodProperties: {
        type: 1,
        idRow,
        productId,
      },
    };
    return this.api.post_jwt(q);
  }

  addSpecInfo(comment: string, idProd: number): Observable<any> {
    const q: RequestBody = {
      modelName: 'ProductLibrary',
      calledMethod: 'addSpecInfo',
      methodProperties: {
        type: 1,
        comment,
        productId: idProd,
      },
    };
    return this.api.post_jwt(q);
  }

  loadCrossList(value): Observable<any> {
    const q: RequestBody = {
      modelName: 'ProductLibrary',
      calledMethod: 'loadCrossList',
      methodProperties: {
        type: 1,
        ...value,
      },
    };
    return this.api.post_jwt(q);
  }

  deleteCross(crossProd: any, idProd: number): Observable<any> {
    const q: RequestBody = {
      modelName: 'ProductLibrary',
      calledMethod: 'deleteCross',
      methodProperties: {
        type: 1,
        crossProd,
        productId: idProd,
      },
    };
    return this.api.post_jwt(q);
  }

  loadBrandCrossList(value): Observable<OtherSpr[]> {
    const q: RequestBody = {
      modelName: 'ProductLibrary',
      calledMethod: 'loadBrandCrossList',
      methodProperties: {
        type: 1,
        ...value,
      },
    };
    return this.api.post_jwt(q);
  }

  addNewCross(value: any, idProd: number): Observable<any> {
    const q: RequestBody = {
      modelName: 'ProductLibrary',
      calledMethod: 'addNewCross',
      methodProperties: {
        type: 1,
        ...value,
        productId: idProd,
      },
    };
    return this.api.post_jwt(q);
  }

  loadCriProd(value): Observable<any> {
    const q: RequestBody = {
      modelName: 'ProductLibrary',
      calledMethod: 'loadCriProd',
      methodProperties: {
        type: 1,
        ...value,
      },
    };
    return this.api.post_jwt(q);
  }

  deleteCri(criId: any, idProd: number): Observable<any> {
    const q: RequestBody = {
      modelName: 'ProductLibrary',
      calledMethod: 'deleteCri',
      methodProperties: {
        type: 1,
        criId,
        productId: idProd,
      },
    };
    return this.api.post_jwt(q);
  }

  loadCriListInfo(): Observable<any> {
    const q: RequestBody = {
      modelName: 'ProductLibrary',
      calledMethod: 'loadAllCri',
      methodProperties: {
        type: 1,
      },
    };
    return this.api.post_jwt(q);
  }

  loadCriValueRu(value): Observable<any> {
    const q: RequestBody = {
      modelName: 'ProductLibrary',
      calledMethod: 'loadCriValueRu',
      methodProperties: {
        type: 1,
        ...value,
      },
    };
    return this.api.post_jwt(q);
  }

  saveNewProductCri(value: {
    productId: any[];
    criRu: any;
    valueRu: any;
  }): Observable<any> {
    const q: RequestBody = {
      modelName: 'ProductLibrary',
      calledMethod: 'saveNewProductCri',
      methodProperties: {
        type: 1,
        ...value,
      },
    };
    return this.api.post_jwt(q);
  }

  loadCarProd(value): Observable<any> {
    const q: RequestBody = {
      modelName: 'ProductLibrary',
      calledMethod: 'loadCarProd',
      methodProperties: {
        type: 1,
        ...value,
      },
    };
    return this.api.post_jwt(q);
  }

  deleteCar(id: any, idProd: number): Observable<any> {
    const q: RequestBody = {
      modelName: 'ProductLibrary',
      calledMethod: 'deleteCar',
      methodProperties: {
        type: 1,
        carId: id,
        productId: idProd,
      },
    };
    return this.api.post_jwt(q);
  }

  deleteCarTypes(id, idProd: number): Observable<any> {
    const q: RequestBody = {
      modelName: 'ProductLibrary',
      calledMethod: 'deleteCarTypes',
      methodProperties: {
        type: 1,
        carId: id,
        productId: idProd,
      },
    };
    return this.api.post_jwt(q);
  }

  loadProductMarkList(): Observable<any> {
    const q: RequestBody = {
      modelName: 'ProductLibrary',
      calledMethod: 'loadProductMarkList',
      methodProperties: {
        type: 1,
      },
    };
    return this.api.post_jwt(q);
  }

  loadProductLoadModel(value): Observable<any> {
    const q: RequestBody = {
      modelName: 'ProductLibrary',
      calledMethod: 'loadProductLoadModel',
      methodProperties: {
        type: 1,
        ...value,
      },
    };
    return this.api.post_jwt(q);
  }

  saveNewProductCar(value): Observable<any> {
    const q: RequestBody = {
      modelName: 'ProductLibrary',
      calledMethod: 'saveNewProductCar',
      methodProperties: {
        type: 1,
        ...value,
      },
    };
    return this.api.post_jwt(q);
  }

  loadPhotoProd(value): Observable<any> {
    const q: RequestBody = {
      modelName: 'ProductLibrary',
      calledMethod: 'loadPhotoProd',
      methodProperties: {
        type: 1,
        ...value,
      },
    };
    return this.api.post_jwt(q);
  }

  uploadFile(file: FormData): Observable<{ code: number; response: string }> {
    return this.api.uploadFileProductCard(file);
  }

  deletePhoto(id, idProd): Observable<any> {
    const q: RequestBody = {
      modelName: 'ProductLibrary',
      calledMethod: 'deletePhoto',
      methodProperties: {
        type: 1,
        idPhoto: id,
        productId: idProd,
      },
    };
    return this.api.post_jwt(q);
  }

  loadTreeToFid(obj?: any): Observable<SprList[]> {
    const q: RequestBody = {
      modelName: 'FidTools',
      calledMethod: 'loadTreeToFid',
      methodProperties: {
        ...obj,
      },
    };

    return this.api.post_jwt(q);
  }

  saveGrpProd(obj: { grpProd: any; id: number }): Observable<any> {
    const q: RequestBody = {
      modelName: 'ProductLibrary',
      calledMethod: 'saveGrpProd',
      methodProperties: {
        ...obj,
      },
    };

    return this.api.post_jwt(q);
  }

  setOnePriorityPhoto(id, idProd: number): Observable<any> {
    const q: RequestBody = {
      modelName: 'ProductLibrary',
      calledMethod: 'setOnePriorityPhoto',
      methodProperties: {
        type: 1,
        idPhoto: id,
        productId: idProd,
      },
    };
    return this.api.post_jwt(q);
  }

  saveCriInfo(param: {
    infoUa: any;
    infoRu: any;
    idProd: any;
  }): Observable<any> {
    const q: RequestBody = {
      modelName: 'ProductLibrary',
      calledMethod: 'saveCriInfo',
      methodProperties: { ...param, type: 1 },
    };
    return this.api.post_jwt(q);
  }

  setItemGroup(id, idProd: number): Observable<any> {
    const q: RequestBody = {
      modelName: 'ProductLibrary',
      calledMethod: 'setItemGroup',
      methodProperties: {
        type: 1,
        idPhoto: id,
        productId: idProd,
      },
    };
    return this.api.post_jwt(q);
  }
}
